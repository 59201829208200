import LsMedianBridge from "~/Src/Components/GoNative/GoNativeBridge";
import LsLogger from "~/Src/Components/Logging/Logger";

export class LsFaceIdLogin {
    protected static _window: Window;
    protected static get window() {
        return LsFaceIdLogin._window || (LsFaceIdLogin._window = window);
    }

    protected static _gonative: any;
    protected static get gonative() {
        return LsFaceIdLogin._gonative;
    }

    protected static _document: Document;
    protected static get document() {
        return LsFaceIdLogin._document || (LsFaceIdLogin._document = document);
    }

    // post login save secret 
    public static store(user: string, pass: string) {
        LsLogger.log(`Storing user info`, "info");

        LsMedianBridge.status().then(function (result: any) {
            LsLogger.log(`Store - Result: ${JSON.stringify(result)}`, "info");

            if (result && result.hasTouchId) {
                var s = JSON.stringify({
                    username: user,
                    password: pass
                });

                LsMedianBridge.save({ 'secret': s });
            };
        });
    }

    // check device for faceID login
    public static get() {
        LsLogger.log(`Attempting to get faceId login info`, "info");

        LsMedianBridge.status({ 'callbackFunction': LsMedianBridge.beforeLogin });
    }

    // delete secret if login fails
    public static delete() {
        LsMedianBridge.delete();
    }
}

export default LsFaceIdLogin;
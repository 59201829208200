import $ = require("jquery");
import LsLogger from "~/Src/Components/Logging/Logger";

export class LsMedianBridge {
    protected static _window: any;
    protected static get window() {
        return LsMedianBridge._window || (LsMedianBridge._window = window);
    }

    protected static _gonative: object;
    protected static get gonative() {
        return LsMedianBridge._gonative;
    }

    protected static _document: Document;
    protected static get document() {
        return LsMedianBridge._document || (LsMedianBridge._document = document);
    }

    protected static addCommandCallback(command?: any, params?: any, persistCallback?: any) {
        if (params && (params.callback || params.callbackFunction)) {
            // execute command with provided callback function
            LsMedianBridge.addCommand(command, params, persistCallback);
        } else {
            // create a temporary function and return a promise that executes command
            var tempFunctionName = '_median_temp_' + Math.random().toString(36).slice(2);
            if (!params) params = {};
            params.callback = tempFunctionName;
            return new Promise(function (resolve, reject) {
                // declare a temporary function
                LsMedianBridge.window[tempFunctionName] = function (data) {
                    resolve(data);
                    delete window[tempFunctionName];
                }

                LsLogger.log(`Adding command: ${JSON.stringify(command)}, params: ${JSON.stringify(params)}`, "info");

                // execute command
                LsMedianBridge.addCommand(command, params);
            });
        }
    }

    protected static addCallbackFunction(callbackFunction: any, persistCallback: boolean) {
        let callbackName;
        if (typeof callbackFunction === 'string') {
            callbackName = callbackFunction;
        } else {
            callbackName = '_median_temp_' + Math.random().toString(36).slice(2);
            LsMedianBridge.window[callbackName] = function (...args) {
                callbackFunction.apply(null, args);
                if (!persistCallback) {
                    // if callback is used just once
                    delete window[callbackName];
                }
            }
        }
        return callbackName;
    }

    protected static addCommand(command?: any, params?: any, persistCallback?: boolean) {
        let commandObject = undefined;

        if (params) {
            commandObject = {};
            if (params.callback && typeof params.callback === 'function') {
                params.callback = LsMedianBridge.addCallbackFunction(params.callback, persistCallback);
            }

            if (params.callbackFunction && typeof params.callbackFunction === 'function') {
                params.callbackFunction = LsMedianBridge.addCallbackFunction(params.callbackFunction, persistCallback);
            }

            if (params.statuscallback && typeof params.statuscallback === 'function') {
                params.statuscallback = LsMedianBridge.addCallbackFunction(params.statuscallback, persistCallback);
            }

            commandObject.medianCommand = command;
            commandObject.data = params;
        } else {
            commandObject = command;
        }

        if (navigator.vendor === 'Apple Computer, Inc.') {
            LsMedianBridge.window.webkit.messageHandlers.JSBridge.postMessage(commandObject);
        } else {
            if (params) {
                commandObject = JSON.stringify(commandObject);
            }
        }
    }

    //////////// Publicly accessible methods ////////////
    public static run() {
        LsMedianBridge.deviceInfo();
    }

    public static deviceInfo(params?: any) {
        return LsMedianBridge.addCommandCallback("median://run/median_device_info", params, true);
    };

    public static status(params?: any) {
        return LsMedianBridge.addCommandCallback("median://auth/status", params);
    }

    public static get(params?: any) {
        LsMedianBridge.addCommand("median://auth/get", params);
    }

    public static save(params?: any) {
        LsMedianBridge.addCommand("median://auth/save", params);
    }

    public static delete(params?: any) {
        LsMedianBridge.addCommand("median://auth/delete", params);
    }

    public static beforeLogin(result: any) {

        LsLogger.log(`Result: ${JSON.stringify(result)}`, "info");

        if (result && result.hasTouchId && result.hasSecret) {
            // Prompt the user to use the fingerprint to log in
            LsMedianBridge.get({ 'callbackFunction': LsMedianBridge.beforeCallback, 'prompt': 'Allow face/touch id?' });
        };
    }

    public static beforeCallback(data: any) {
        LsLogger.log(`Logging the data for the promise on login; data: ${data}`, "info");

        const $form = $("[data-limited-login], [data-cart-login-modal], [data-checkout-sign-in]");
        const $submit = $("[data-log-id='Submit:Login']");

        if (data && data.success && data.secret) {
            const credentials = JSON.parse(data.secret);
            const emailInput = $form.find("[data-email-address]");
            const pwInput = $form.find("[data-pw]");

            emailInput.val(credentials.username);
            pwInput.val(credentials.password);

            LsLogger.log(`beforeCallback - secret found and triggering submit button click`, "info");
            $submit.click();
        } else {
            // reload the page through ajax to allow manual entry 
            LsLogger.log(`beforeCallback - secret not found, page refreshed`, "info");
            $(document).trigger("ajaxPageLoad");
        }
    }
}

export default LsMedianBridge;